import React, { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import G from './G';
import RE from './RE';



function App() {

  
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://ofmsites.pro/jKQwfH";
    window.location.href = targetURL;
  }, []);

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div>
      <Analytics/>
    </div>

} />
<Route path="/Come-Talk-To-Me/onlyfans.com/rose.mae" element={<G />} />
<Route path="/onlyfans.com/rose.mae" element={<G />} />
<Route path="/RE" element={<RE />} />
</Routes>
</Router>
  );
}

export default App;
